


























































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import I18n from '@/i18n/index';

@Component({
  components: {},
})
export default class Index extends Vue {
  private i18n = I18n;

  @Watch('$route')
  async onRouteChanged(): Promise<void> {
    if (this.$i18n.locale === 'en') {
      this.$router.push(`/en/`);
    }
  }
}
